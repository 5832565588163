var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    class: _vm.device + "-event",
    attrs: {
      id: "content"
    }
  }, [_c("div", {
    staticClass: "aboutBannerBox"
  }, [_vm.language == 1 ? _c("img", {
    staticClass: "aboutBanner",
    attrs: {
      src: require("../assets/banner/banners_ir_cn.jpg")
    }
  }) : _vm._e(), _vm.language == 2 ? _c("img", {
    staticClass: "aboutBanner",
    attrs: {
      src: require("../assets/banner/banners_ir_en.jpg")
    }
  }) : _vm._e(), _vm.language == 3 ? _c("img", {
    staticClass: "aboutBanner",
    attrs: {
      src: require("../assets/banner/banners_ir_cn.jpg")
    }
  }) : _vm._e()]), _c("div", {
    staticClass: "companyBlock",
    staticStyle: {
      "padding-bottom": "0"
    }
  }, [_c("div", {
    staticClass: "companyBlock-about"
  }, [_c("div", {
    staticClass: "companyBlock-hd"
  }, [_vm.language == 1 ? _c("h2", [_vm._v("投资者关系")]) : _vm._e(), _vm.language == 2 ? _c("h2", [_vm._v("INVESTORS")]) : _vm._e(), _vm.language == 3 ? _c("h2", [_vm._v("投資者關係")]) : _vm._e()]), _c("div", {
    staticClass: "companyInfo clearfix"
  }, [_c("ul", {
    staticClass: "tMenuLeft",
    class: _vm.language == 2 ? "tMenuLeftEn" : ""
  }, [_vm._l(_vm.irMenuData, function (items, index) {
    return _vm.language == 1 || !_vm.language ? _c("router-link", {
      attrs: {
        to: items.path
      }
    }, [_c("li", {
      class: _vm.activeMenu == items.value ? "active" : ""
    }, [_vm._v(_vm._s(items.name)), _c("em", [_vm._v(">")])])]) : _vm._e();
  }), _vm._l(_vm.irMenuData, function (items, index) {
    return _vm.language == 2 ? _c("router-link", {
      attrs: {
        to: items.path
      }
    }, [_c("li", {
      class: _vm.activeMenu == items.value ? "active" : ""
    }, [_vm._v(_vm._s(items.nameEn)), _c("em", [_vm._v(">")])])]) : _vm._e();
  }), _vm._l(_vm.irMenuData, function (items, index) {
    return _vm.language == 3 ? _c("router-link", {
      attrs: {
        to: items.path
      }
    }, [_c("li", {
      class: _vm.activeMenu == items.value ? "active" : ""
    }, [_vm._v(_vm._s(items.nameTr)), _c("em", [_vm._v(">")])])]) : _vm._e();
  })], 2), _c("div", {
    staticClass: "comContentRight",
    class: _vm.language == 2 ? "comContentRightEn" : "",
    staticStyle: {
      "padding-bottom": "10em"
    }
  }, [_c("ul", _vm._l(_vm.offerList, function (item, index) {
    return _c("a", {
      key: index,
      attrs: {
        href: _vm.title + item.fileSrc,
        target: "_blank"
      }
    }, [_c("li", [_c("p", {
      staticClass: "p1"
    }, [_vm._v(_vm._s(item.fileName))]), _c("span"), _c("p", {
      staticClass: "p2"
    }, [_vm._v(_vm._s(item.createTime))])])]);
  }), 0)])])])])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };