import { mapState } from 'vuex';
export default {
  name: '',
  data() {
    return {
      title: this.$api.api_title,
      offerList: []
    };
  },
  computed: {
    activeMenu() {
      const route = this.$route;
      return route.name;
      console.log("route.name" + route.name);
    },
    ...mapState({
      irMenuData: state => state.irMenuData,
      device: state => state.device,
      language: state => state.language
    })
  },
  watch: {},
  created() {
    this.getData();
  },
  mounted() {},
  methods: {
    getData() {
      this.$http.get(this.$api.investment_offering).then(res => {
        if (res.code == 0) {
          if (res.data) {
            if (this.$store.state.language == 2) {
              this.offerList = res.data.en;
            } else if (this.$store.state.language == 3) {
              this.offerList = res.data.traditional;
            } else if (this.$store.state.language == 1) {
              this.offerList = res.data.cn;
              console.log(this.offerList);
            }
          }
        }
      });
    }
  }
};